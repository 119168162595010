import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-list-questions-faq',
  templateUrl: './list-questions-faq.component.html',
  styleUrls: ['./list-questions-faq.component.css']
})
export class ListQuestionsFaqComponent implements OnInit {
  currTab: any='English';
  faqData: any=[];
  pageNumber:number=1
  faqId: any;
  isPublished: any;
  lan: any;
  totalRecords: any;
  itemsPerPage: number = 20;
  question: any = ''
  catID:any
  catName:any
  qsnID:any
  constructor(public route:Router, public service:ServiceService,private activatedRoute:ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.catID = res.catID
      this.catName = res.catName,
      this.qsnID = res.qsnID
    })
  }

  ngOnInit(): void {
    this.faqList()
  }


  // faq english
  faqList(){
    let url =`static/get-answers?questionId=${this.qsnID}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.faqData=res.data;
        // this.totalRecords = res.data.Count ? res.data.List.totalElements : '';
      }
      this.service.hideSpinner()
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  isSearched: boolean = false;

  pagination(page) {
    this.pageNumber = page;
    if (this.isSearched) {
      this.faqList();
    } else {
      this.faqList();
    }
  }
 
// add faq
  addFaq(){
    this.route.navigate(['/add-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName}})
  }
  // edit faq
  editFaq(ansId){
    this.route.navigate(['/edit-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName,ansId:ansId}})
  }
  viewFaq(ansId){
    this.route.navigate(['/view-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName,ansId:ansId}})
  }
  // open modal
  delete(faqId, tab){
    this.faqId=faqId;
    this.lan=tab
    $('#deleteModal').modal('show')
  }
  // delete faq language
  deleteFaqLanguage(){
      this.deleteUser()
  }
  // delete functionallity of faq english
  deleteUser(){
    let url="static/admin/static-content/delete-FAQ-by-id?faqId="+this.faqId;
    let data={}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
 
  // delete functionallity of faq spanish
  
  // open modal
  publish(publish,faqId,tab){
    this.faqId=faqId;
    this.isPublished=publish;
    this.lan=tab
    $('#publishModal').modal('show')
  }




  edit(){}
  view(){}
  
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  // search advertisement
  search() {
    this.faqData = [];
    this.totalRecords = 0
    this.pageNumber=1;
    let url =`static/admin/static-content/get-FAQ-list?page=${this.pageNumber -1}&pagesize=${this.itemsPerPage}${this.question ? "&question=" + this.question : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.faqData=res.data;
        this.totalRecords = res.data.Count ? res.data.List.totalElements : '';
      }
      this.service.hideSpinner()
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }
  // reset search fields
  reset() {
    this.faqData = [];
    this.totalRecords = 0
    this.pageNumber=1;
    if (this.question) {
      this.question = ''
      this.faqList()
    } else {
    }
  }

  disableSearchbtn(){

    if (this.question )
     {
      return false
     }
     else {
       return true
     }
   }
}
