import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { filter } from 'minimatch';

declare var $: any
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userListLength: any;
  kycpendingListlength: any;
  activeUserLength: any;
  blockedUserLength: any;
  pendingUserLength: any
  coinList: any = [];
  countByKycStatus: any;
  totalUserCount: any;
  Pending_Kyc_Count: any;
  Rejected_Kyc_Count: any;
  depositCount: any;
  withdrawCountComplete: any;
  withdrawCountPending: any;
  coinCount: any;
  fiatDepositComplete: any;
  fiatDepositPending: any;
  fiatDepositRejected: any;
  fiatWithdrawComplete: any;
  fiatWithdrawPending: any;
  fiatWithdarwRejected: any;

  constructor(
    private service: ServiceService,
    private router: Router,
    private toastr: ToastrManager,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
  ) {

  }

  ngOnInit() {
    this.getUserList();

  }
  getCountDetails() {
    this.service.get('account/admin/dashboard/dashboardApi').subscribe((res) => {
    })
  }
  // Get List of User
  totalNominee: any
  rejectedNominee: any
  approveNominee: any
  pendingNominee: any
  approvNominee: any
  getUserList() {
    var url = "account/admin/dashboard/dashboard";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {

        var userList = res['data'];
        this.activeUserLength = userList.totalActiveUsers;
        this.blockedUserLength = userList.totalBlockedUsers;
        this.countByKycStatus = userList.totalApprovedKyc + userList.totalPendingKyc + userList.totalRejectedKyc;
        this.pendingUserLength = userList.totalUnverifiedUsers;
       this.Pending_Kyc_Count = userList.totalPendingKyc
       this.Rejected_Kyc_Count = userList.totalRejectedKyc
       this.depositCount = userList.walletDashboard.depositCrypto
       this.withdrawCountComplete = userList.walletDashboard.confirmWithdrawCount
       this.withdrawCountPending = userList.walletDashboard.pendingWithdrawCount
       this.coinCount = userList.walletDashboard.totalCrypto
       this.fiatDepositComplete = userList.walletDashboard.confirmFiatDeposit
       this.fiatDepositPending = userList.walletDashboard.pendingFiatDeposit
       this.fiatDepositRejected = userList.walletDashboard.rejectedFiatDeposit
       this.fiatWithdrawComplete = userList.walletDashboard.confirmFiatWithdraw
       this.fiatWithdrawPending = userList.walletDashboard.pendingFiatWithdraw
       this.fiatWithdarwRejected = userList.walletDashboard.rejectedFiatWithdraw
       this.userListLength = this.activeUserLength + this.blockedUserLength + this.pendingUserLength;
       
        
       
       
       
       this.totalUserCount = userList.totalUserCount;
        this.totalNominee = userList.nomineeCount;
        this.rejectedNominee = userList.nomineeRejectedCount;
        this.pendingNominee = userList.nomineeCountPending
        this.approvNominee = userList.nomineeCountAccepted
      } else {

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

 
}
