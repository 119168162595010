import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-faq-category',
  templateUrl: './edit-faq-category.component.html',
  styleUrls: ['./edit-faq-category.component.css']
})
export class EditFaqCategoryComponent implements OnInit {
  addFaqForm : FormGroup
  id:any
  constructor(public service:ServiceService,private router:Router,private activatedR:ActivatedRoute) {
    this.activatedR.queryParams.subscribe(res=>{
      this.id = res.id
    }) }

  ngOnInit() {
    this.formValidation()
    this.getCat()
  }
  formValidation() {
    this.addFaqForm = new FormGroup({
      'faq_category': new FormControl('', [Validators.required]),
      // 'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      // 'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }

  getCat(){
    let url =  `static/admin/get-request-category?categoryId=${this.id}`
    this.service.get(url).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.addFaqForm.patchValue({
          faq_category : res.data.category
        })
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  addFaq(){
    let url = `static/admin/update-request-category?categoryId=${this.id}`
    let request = {
      category : this.addFaqForm.value.faq_category
    }
    this.service.put(url, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.router.navigate(['/faq-category'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
}
