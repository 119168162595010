import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-admin-bank',
  templateUrl: './admin-bank.component.html',
  styleUrls: ['./admin-bank.component.css']
})
export class AdminBankComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  currTab:any = 'HOT';
  addSwatchesFormUpi:FormGroup
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
  this.addUpiForm()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'bankName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'accountNumber': new FormControl('', [Validators.required]),
      'accountHolderName': new FormControl('',[Validators.required,Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'ifscCode': new FormControl('',Validators.required)
    })
  }
addUpiForm(){
  this.addSwatchesFormUpi =  new FormGroup({
    'name': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
    'upiID': new FormControl('', [Validators.required]),
  })
}
  addSwatches() {
    let url = `wallet/add-user-bank-account?role=ADMIN`
    let data = {
      "accountHolderName": this.addSwatchesForm.value.accountHolderName,
      "accountNo": this.addSwatchesForm.value.accountNumber,
      "accountType": "string",
      "bankName": this.addSwatchesForm.value.bankName,
      "contactNo": "string",
      "ibanNo": "string",
      "ifsc": this.addSwatchesForm.value.ifscCode,
      "imageUrl": "string",
      "swiftNo": "string"
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/admin-bank-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  addUpi() {
    let url = `wallet/admin/add-Upi-details`
    let data = {
      'name': this.addSwatchesFormUpi.value.name,
      'upiId': this.addSwatchesFormUpi.value.upiID,
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/admin-bank-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  selectTab(tab) {
    this.currTab = tab;
    if(tab == 'HOT'){
    }else{
    }
}
}
