import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-faq-category-add',
  templateUrl: './faq-category-add.component.html',
  styleUrls: ['./faq-category-add.component.css']
})
export class FaqCategoryAddComponent implements OnInit {
  addFaqForm : FormGroup
  id:any
  constructor(public service:ServiceService,private router:Router){
   }

  ngOnInit() {
    this.formValidation()
  }
  formValidation() {
    this.addFaqForm = new FormGroup({
      'faq_category': new FormControl('', [Validators.required]),
      // 'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      // 'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }
  addFaq(){
    let request = {
      category : this.addFaqForm.value.faq_category
    }
    this.service.post(`static/admin/add-request-category`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.router.navigate(['/faq-category'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
 
  
}
