import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-staff',
  templateUrl: './view-staff.component.html',
  styleUrls: ['./view-staff.component.css']
})
export class ViewStaffComponent implements OnInit {
  id: any;
  staffDetails: any;
  editStaffForm: FormGroup;
  ipAddress: any;
  staffPrivileges: any = [];
  newArr: any = [];
  checkedBoolead: boolean = true;

  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
    });
    this.http.get<{ ip: string }>('https://jsonip.com/?format=json')
    .subscribe(data => {
      this.ipAddress = data['IPv4']
    })
  }

  ngOnInit() {
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      dashboardChecked: new FormControl(''),
      staffChecked: new FormControl(''),
      hotWalletChecked: new FormControl(''),
      kycChecked: new FormControl(''),
      walletChecked: new FormControl(''),
      staticChecked: new FormControl(''),
      logsChecked: new FormControl(''),
      ticketChecked: new FormControl(''),
      tradeChecked: new FormControl(''),
      disputeChecked: new FormControl(''),
      bankChecked: new FormControl(''),
      feeChecked: new FormControl(''),
      escrowChecked:new FormControl(''),
      feedbackChecked :new FormControl(''),
      usermanagementChecked:new FormControl(''),
      // usermanagementofexchangeChecked:new FormControl(''),
      advertiesmentChecked:new FormControl(''),
      faqChecked: new FormControl(''),
      settingChecked: new FormControl(''),
      fundChecked : new FormControl(''),
      unifiedChecked : new FormControl('')
    })
    this.getProfile();
  }

  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.get('account/admin/user-management/user-details?userId='+this.id).subscribe((res) => {
      this.staffDetails = res['data'];
      this.staffPrivileges = res['data']['previlage'];
      this.staffPrivileges.forEach(element => {

        if (element == 'DASHBOARD') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            dashboardChecked: this.checkedBoolead,
          })
        }
        else if (element == 'USER_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            usermanagementChecked: this.checkedBoolead,
          })
        }
        // else if (element == 'EXCHANGE_MANAGEMENT') {
        //   this.newArr.push(element);
        //   this.editStaffForm.patchValue({
        //     usermanagementofexchangeChecked: this.checkedBoolead,
        //   })
        // }
        else if (element == 'HOT_COLD_LIMIT_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            hotWalletChecked: this.checkedBoolead,
          })
        }
        else if (element == 'ADVERTISEMENTS') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            advertiesmentChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FUND_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            fundChecked: this.checkedBoolead,
          })
        }
        // else if (element == 'XINDIA_MANAGEMENT') {
        //   this.newArr.push(element);
        //   this.editStaffForm.patchValue({
        //     xindiaChecked: this.checkedBoolead,
        //   })
        // }
        else if (element == 'FAQ_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            faqChecked: this.checkedBoolead,
          })
        }
        else if (element == 'SETTINGS') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            settingChecked: this.checkedBoolead,
          })
        }
        else if (element == 'KYC_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            kycChecked: this.checkedBoolead,
          })
        }
        else if (element == 'WALLET_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            walletChecked: this.checkedBoolead,
          })
        }
        else if (element == 'STATIC_CONTENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            staticChecked: this.checkedBoolead,
          })
        }
        else if (element == 'LOGS_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            logsChecked: this.checkedBoolead,
          })
        }
        else if (element == 'TICKET_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            ticketChecked: this.checkedBoolead,
          })
        }
        else if (element == 'TRADE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            tradeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'DISPUTE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            disputeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FEEDBACK_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            feedbackChecked: this.checkedBoolead,
          })
        }
        else if (element == 'STAFF_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            staffChecked: this.checkedBoolead,
          })
        }
        else if (element == 'FEE_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            feeChecked: this.checkedBoolead,
          })
        }
        else if (element == 'BANK_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            bankChecked: this.checkedBoolead,
          })
        }
        else if (element == 'UNIFIED') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            unifiedChecked: this.checkedBoolead,
          })
        }
        else if (element == 'ESCROW_MANAGEMENT') {
          this.newArr.push(element);
          this.editStaffForm.patchValue({
            escrowChecked: this.checkedBoolead,
          })
        }
      });
      this.editStaffForm.patchValue({
        firstName: this.staffDetails.firstName,
        lastName: this.staffDetails.lastName,
        email: this.staffDetails.email,
        gender: this.staffDetails.gender,
        role: this.staffDetails.role,
        phoneNumber: this.staffDetails.phoneNo
      })
      setTimeout(() => {
        this.service.hideSpinner();
      }, 2000)

    })
  }

  editStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.editStaffForm.value.email,
      "firstName": this.editStaffForm.value.firstName,
      "gender": this.editStaffForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress,
      "lastName": this.editStaffForm.value.lastName,
      "location": "string",
      "phoneNo": this.editStaffForm.value.phoneNumber,
      "previlage": this.newArr,
      "roleStatus": "SUBADMIN",
      "state": "string",
      "webUrl": "string",
      "userIdToUpdate": this.id
    }
    this.service.post('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      this.router.navigate(['/staff-management'])
    })
  }

  /** Function for checkbox click */
  checkboxClick(value, checked) {
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      this.newArr.splice(index, 1)
    }
  }


  back() {
    this.router.navigate(['/staff-management'])
  }
  edit(id) {
    this.router.navigate(['/edit-staff'], {queryParams:{id}})
  }

  // getProfile() {
  //   this.service.showSpinner();
  //   let dataa = {
  //     "primaryIdCommonPerRequest": this.id
  //   }
  //   this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res)=>{
  //     this.staffDetails = res['data']['staffDetails']
  //     setTimeout(()=>{
  //       this.service.hideSpinner();
  //     },2000)
      
  //   })
  // }

}
