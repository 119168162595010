import { logging } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-view-deposit',
  templateUrl: './view-deposit.component.html',
  styleUrls: ['./view-deposit.component.css']
})
export class ViewDepositComponent implements OnInit {

  userKycId: any;
  kycData: any = {};
  kycDocList: any = [];
  rejectionReason: string = '';
  documentId: any;
  username: any;
  userId: any;
  documentIdd: any;
  kycIdd: any;
  kycStatus: string;
  ButtonDisable: any;
  data: any;
  fiatId: any
  fiatData: any
  complete: any
  pending: any
  fiatStatus: any
  utrNo: any
  reject: any
  constructor(public activatedRoute: ActivatedRoute, public service: ServiceService, private router: Router) {
  
 this.fiatData = JSON.parse(sessionStorage.getItem('data'))
 console.log(this.fiatData);
 
  }

  ngOnInit() {
  }
  back() {
    this.router.navigate(['/list-fund'])
  }

  approveKyc(status) {
    this.complete = status
      $('#approveModal').modal('show')
  }
rejectKyc(status){
  this.complete = status

  $('#reject').modal('show')

}
  openModal(status) {
    this.complete = status
    if(status == 'CONFIRM'){
      $('#reject').modal('show')
    }else{
    $('#approveModal').modal('show')
    }
  }

  //wallet/approve-reject-fiat-request?fiatStatus=PENDING&id=1&transactionType=DEPOSIT
  // rejectKyc(status) {
  //   this.reject = status
  //   $('#reject').modal('hide')
  // }



  approve() {
    this.service.showSpinner()
    let data = {}
    this.service.putApi(`wallet/approve-reject-fiat-request?fiatStatus=COMPLETED&id=${this.fiatData.txnId}&transactionType=DEPOSIT`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.fiatData.status = 'CONFIRM'
        $('#approveModal').modal('hide')
        this.service.hideSpinner()
      }
    })
  }
rejectInrDepositFund(){
  this.service.showSpinner()
  let url =`wallet/approve-reject-fiat-request?fiatStatus=REJECTED&id=${this.fiatData.txnId}&transactionType=DEPOSIT`
  this.service.putApi(url, {}).subscribe((res) => {
    if (res['status'] == 200) {
      this.fiatData.status = 'REJECTED'
      $('#reject').modal('hide')
      this.service.hideSpinner()
    }
  })
}
}
