import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-questions-faq',
  templateUrl: './add-questions-faq.component.html',
  styleUrls: ['./add-questions-faq.component.css']
})
export class AddQuestionsFaqComponent implements OnInit {
  addFaqForm : FormGroup
  id:any
  catID:any
  catName:any
  qsnID:any
  constructor(public service:ServiceService,private router:Router,private activatedRoute:ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.catID = res.catID
      this.catName = res.catName,
      this.qsnID = res.qsnID
    })
   }

  ngOnInit() {
    this.formValidation()
  }
  formValidation() {
    this.addFaqForm = new FormGroup({
      'faq_category': new FormControl('', [Validators.required]),
      // 'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      // 'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }
  addFaq(){
    let request = {
      answer : this.addFaqForm.value.faq_category,
      subCategoryId : this.qsnID
    }
    this.service.post(`static/admin/add-answers`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.router.navigate(['/list-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName}})
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // add faq
  back(){
    this.router.navigate(['/list-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName}})
  }
 
}
