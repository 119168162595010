import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-questions-faq',
  templateUrl: './view-questions-faq.component.html',
  styleUrls: ['./view-questions-faq.component.css']
})
export class ViewQuestionsFaqComponent implements OnInit {
  id:any
  catID:any
  catName:any
  qsnID:any
  ansId:any
  viewFaqForm: FormGroup;

  constructor(public service:ServiceService,private router:Router,private activatedRoute:ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.catID = res.catID
      this.catName = res.catName,
      this.qsnID = res.qsnID,
      this.ansId = res.ansId
    })
   }
  ngOnInit() {
    this.viewFaqForm = new FormGroup({
      'title': new FormControl(''),
    })
    this.getAnsewer()
  }
  editData:any
  getAnsewer(){
   let url =`static/admin/get-answer?answerId=${this.ansId}`
   this.service.get(url).subscribe((res: any) => {
     if (res.status = 200) {
       this.editData = res.data; 
        //     this.viewFaqForm.patchValue({
        //   'title': this.editData.answer,
        // })
     }
   }, err => {
     this.service.hideSpinner();
     if (err['status'] == '401') {
       this.service.onLogout();
       this.service.toasterErr('Unauthorized Access');
     } else {
       this.service.toasterErr('Something Went Wrong');
     }
   })
  }
  // add faq
  back(){
    this.router.navigate(['/list-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName,ansId:this.ansId}})
  }
}
