import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $: any
@Component({
  selector: 'app-escrow-management',
  templateUrl: './escrow-management.component.html',
  styleUrls: ['./escrow-management.component.css']
})
export class EscrowManagementComponent implements OnInit {
  escrowList: any = [];
  pageNumber: number = 1;
  pageSize: number = 20
  transferForm: FormGroup
  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getTradeEscrowData()
  }

  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'kycStatus': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'search': new FormControl(''),
      'country': new FormControl(''),
    })
  }

  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.search ||
      this.transferForm.value.kycStatus ||
      this.transferForm.value.country
    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        country: "",
        search: "",
        kycStatus: "",
      });
      this.getTradeEscrowData();
    }
  }
  totalItems: any
  // Get List of User
  getEscrowList() {
    var url = "wallet/admin/get-escrow-list?page=" + (this.pageNumber - 1) + '&pageSize=' + this.pageSize;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.escrowList = res['data']['list']['content'];
        this.totalItems = res['data']['count']
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  disputedId: any
  cancelId: any
  release(id) {
    $('#ReleaseModal').modal('show')
    this.disputedId = id
  }
  cancel(id) {
    $('#CancelModal').modal('show')
    this.cancelId = id
  }
  //return coins in wallet of user1
  returnCoins() {
    this.service.get('wallet/wallet/request-cancel-ESCROW?userToAdminTransferId=' + this.cancelId).subscribe(res => {
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        $('#CancelModal').modal('hide')
        this.getEscrowList()
      } else {
        this.service.toasterErr(res['message'])
      }
    })
  }
  // release coins to user2
  releaseCoins() {
    var url = "wallet/admin/approvedBy-user1?referenceId=" + this.disputedId;
    this.service.showSpinner();
    this.service.post(url, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        $('#ReleaseModal').modal('hide')
        this.getEscrowList()
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // trade escrow data list 
  getTradeEscrowData(page?) {
    let currentPage = page ? page : this.pageNumber
    var url = "p2p-exchange/admin/Escrow-List?page=" + (currentPage - 1) + '&pageSize=' + this.pageSize;
    if(this.transferForm.value.kycStatus){
      url += '&statusType='+this.transferForm.value.kycStatus
    }
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.escrowList = res['data']['Data']['content'];
        this.totalItems = res['data']['Data']['totalElements']
      }
      else{
        this.escrowList = []
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {

        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.escrowList = []
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  ekFunctionZindgiKa() {
    let kycStatus = this.transferForm.value.kycStatus
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber - 1}&pageSize=${this.pageSize}${this.transferForm.value.kycStatus ? "&status=" + kycStatus : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.escrowList = res['data']['Data']['content'];
          this.totalItems = res['data']['Data']['totalElements']
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  disableSearchbtn(){

    // if (this.tradeStatus || this.country  ||  this.fromDate || this.toDate)


    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.search ||
      this.transferForm.value.kycStatus ||
      this.transferForm.value.country
    )
     {
      return false
     }
     else {
       return true
     }
   }


}
