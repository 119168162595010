import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceService } from './service.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './user-management/user-management.component';
//import { HotWalletManagementComponent } from './hot-wallet-management/hot-wallet-management.component';
import { MarketManagementComponent } from './market-management/market-management.component';
import { SettingComponent } from './setting/setting.component';
import { SubAdminManagementComponent } from './sub-admin-management/sub-admin-management.component';
import { WalletManagementComponent } from './wallet-management/wallet-management.component';
import { StaticsContentComponent } from './statics-content/statics-content.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { TermAndServiceComponent } from './term-and-service/term-and-service.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ExchangeManagementComponent } from './exchange-management/exchange-management.component';
import { PaymentAndTransactionComponent } from './payment-and-transaction/payment-and-transaction.component';
import { KycManagementComponent } from './kyc-management/kyc-management.component';
import { TicketManagementComponent } from './ticket-management/ticket-management.component';
import { AddWalletAddressComponent } from './add-wallet-address/add-wallet-address.component';
import { WithdrawlFeeComponent } from './withdrawl-fee/withdrawl-fee.component';
import { DailyLimitComponent } from './daily-limit/daily-limit.component';
import { WithdrawlLimitComponent } from './withdrawl-limit/withdrawl-limit.component';
import { PrevilageSettingComponent } from './previlage-setting/previlage-setting.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { BannerSettingComponent } from './banner-setting/banner-setting.component';
import { WebsiteContentSettingComponent } from './website-content-setting/website-content-setting.component';
import { KycActionPageComponent } from './kyc-action-page/kyc-action-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FiatManagementComponent } from './fiat-management/fiat-management.component';
import { FeeManagementComponent } from './fee-management/fee-management.component';
import { HotcoldwalletManagementComponent } from './hotcoldwallet-management/hotcoldwallet-management.component';
import { ViewtransactionComponent } from './viewtransaction/viewtransaction.component';
import { TradeManagementComponent } from './trade-management/trade-management.component';
import { DisputeManagementComponent } from './dispute-management/dispute-management.component';
import { TradeDetailsComponent } from './trade-details/trade-details.component';
import { DisputeTradeDetailsComponent } from './dispute-trade-details/dispute-trade-details.component';
import { CreateSubadminComponent } from './create-subadmin/create-subadmin.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { EnquireManagementComponent } from './enquire-management/enquire-management.component';
import { WithdrawLimitComponent } from './withdraw-limit/withdraw-limit.component';
import { WalletdetailsComponent } from './walletdetails/walletdetails.component';
import { StaffManagementComponent } from './staff-management/staff-management.component';
import { AddNewStaffComponent } from './add-new-staff/add-new-staff.component';
import { EditStaffComponent } from './edit-staff/edit-staff.component';
import { ViewStaffComponent } from './view-staff/view-staff.component';
import { FooterComponent } from './footer/footer.component';
import { AdminManagementComponent } from './admin-management/admin-management.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import { ViewAdminComponent } from './view-admin/view-admin.component';
import { UserManagementExchangeComponent } from './user-management-exchange/user-management-exchange.component';
import { ViewUserManagementExchangeComponent } from './view-user-management-exchange/view-user-management-exchange.component';
import { ViewUserManagementExchangeOfFeedbackComponent } from './view-user-management-exchange-of-feedback/view-user-management-exchange-of-feedback.component';
import { ViewUserTradingComponent } from './view-user-trading/view-user-trading.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TakerMakerFeeComponent } from './taker-maker-fee/taker-maker-fee.component';
import { BankManagementComponent } from './bank-management/bank-management.component';
import { LogsManagementComponent } from './logs-management/logs-management.component';
import { DatePipe } from '@angular/common';
import { AdvertisementManagementComponent } from './advertisement-management/advertisement-management.component';
import { AdvertisementDetailsComponent } from './advertisement-details/advertisement-details.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { TicketReplyComponent } from './ticket-reply/ticket-reply.component'
import { CKEditorModule } from 'ngx-ckeditor';
import { MyDatePicker, MyDatePickerModule } from 'mydatepicker';
import { EscrowManagementComponent } from './escrow-management/escrow-management.component';
import { ReplaceUnderScorePipe } from './pipes/replace-under-score.pipe';
import { CustomPipePipe } from './pipes/custom-pipe.pipe';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FeedbackListComponent } from './feedback-management/feedback-list/feedback-list.component';
import { FeedbackViewComponent } from './feedback-management/feedback-view/feedback-view.component';
import { AddFaqComponent } from './faq-management/add-faq/add-faq.component';
import { EditFaqComponent } from './faq-management/edit-faq/edit-faq.component';
import { FaqComponent } from './faq-management/faq/faq.component';
import { ViewFaqComponent } from './faq-management/view-faq/view-faq.component';
import { AddBlogComponent } from './blog-management/add-blog/add-blog.component';
import { EditBlogComponent } from './blog-management/edit-blog/edit-blog.component';
import { ListBlogComponent } from './blog-management/list-blog/list-blog.component';
import { ViewBlogComponent } from './blog-management/view-blog/view-blog.component';
import { StaticContentAddComponent } from './statics-content/static-content-add/static-content-add.component';
import { ListAnnouncementComponent } from './announcement-management/list-announcement/list-announcement.component';
import { EditAnnouncementComponent } from './announcement-management/edit-announcement/edit-announcement.component';
import { AddAnnouncementComponent } from './announcement-management/add-announcement/add-announcement.component';
import { ViewAnnouncementComponent } from './announcement-management/view-announcement/view-announcement.component';
import { PartnershipComponent } from './partnership/partnership.component';
import { ContactusManagementComponent } from './contactus-management/contactus-management.component';
import { CommunityAddComponent } from './community-management/community-add/community-add.component';
import { CommunityEditComponent } from './community-management/community-edit/community-edit.component';
import { CommunityListComponent } from './community-management/community-list/community-list.component';
import { CarrerCategoryListComponent } from './career-management/carrer-category-list/carrer-category-list.component';
import { CarrerCategoryAddComponent } from './career-management/carrer-category-add/carrer-category-add.component';
import { CareerCategoryEditComponent } from './career-management/career-category-edit/career-category-edit.component';
import { CareerCategoryViewComponent } from './career-management/career-category-view/career-category-view.component';
import { ListingUserListComponent } from './listing-managment/listing-user-list/listing-user-list.component';
import { CareerSubcategoryEditComponent } from './career-subcategory-edit/career-subcategory-edit.component';
import { CareerSubcategoryAddComponent } from './career-subcategory-add/career-subcategory-add.component';
import { CareerSubcategoryListComponent } from './career-subcategory-list/career-subcategory-list.component';
import { CareerSubcategoryViewComponent } from './career-subcategory-view/career-subcategory-view.component';
import { ListingUserViewComponent } from './listing-managment/listing-user-view/listing-user-view.component';
import { UserDeatilsNomineeComponent } from './user-deatils-nominee/user-deatils-nominee.component';
import { ContactusViewComponent } from './contactus-management/contactus-view/contactus-view.component';
import { ListingCoinComponent } from './listing-coin/listing-coin.component';
import { ManageCoinListComponent } from './manage-coin-list/manage-coin-list.component';
import { TokenManagementComponent } from './token-management/token-management.component';
import { FundsListComponent } from './funds-management/funds-list/funds-list.component';
import { AdminBankComponent } from './bank-management/admin-bank/admin-bank.component';
import { AdminBankListComponent } from './bank-management/admin-bank-list/admin-bank-list.component';
import { AdminBankViewComponent } from './bank-management/admin-bank-view/admin-bank-view.component';
import { ViewFundWithdrawComponent } from './funds-management/view-fund-withdraw/view-fund-withdraw.component';
import { ViewFundUpiComponent } from './funds-management/view-fund-upi/view-fund-upi.component';
import { ListingCoinEditComponent } from './listing-coin-edit/listing-coin-edit.component';
import { AddTeamComponent } from './team-management/add-team/add-team.component';
import { EditTeamComponent } from './team-management/edit-team/edit-team.component';
import { ListTeamComponent } from './team-management/list-team/list-team.component';
import { ViewTeamComponent } from './team-management/view-team/view-team.component';
import { XindiaDetailsListComponent } from './xindia-management/xindia-details-list/xindia-details-list.component';
import { XindiaDetailsAddComponent } from './xindia-management/xindia-details-add/xindia-details-add.component';
import { XindiaHoldingAddComponent } from './xindia-management/xindia-holding-add/xindia-holding-add.component';
import { AddXindiaTableComponent } from './xindia-management/add-xindia-table/add-xindia-table.component';
import { EditXindiaTableComponent } from './xindia-management/edit-xindia-table/edit-xindia-table.component';
import { ViewXindiaTableComponent } from './xindia-management/view-xindia-table/view-xindia-table.component';
import { XindiaBurnedstatusComponent } from './xindia-management/xindia-burnedstatus/xindia-burnedstatus.component';
import { ListPartnershipComponent } from './partnership-management/list-partnership/list-partnership.component';
import { AddPartnershipComponent } from './partnership-management/add-partnership/add-partnership.component';
import { ViewPartnershipComponent } from './partnership-management/view-partnership/view-partnership.component';
import { EditPartnershipComponent } from './partnership-management/edit-partnership/edit-partnership.component';
import { ListBannerComponent } from './banner-management/list-banner/list-banner.component';
import { AddBannerComponent } from './banner-management/add-banner/add-banner.component';
import { EditBannerComponent } from './banner-management/edit-banner/edit-banner.component';
import { ViewBannerComponent } from './banner-management/view-banner/view-banner.component';
import { ReferralComponent } from './referral/referral.component';
import { ViewDepositComponent } from './funds-management/view-deposit/view-deposit.component';
import { ViewWithdrawComponent } from './funds-management/view-withdraw/view-withdraw.component';
import { CommissionManagementListComponent } from './commission-management/commission-management-list/commission-management-list.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { NomineeManagementComponent } from './nominee-management/nominee-management.component';
import { NomineeViewComponent } from './nominee-view/nominee-view.component';
import { AuthTemplateComponent } from './auth-template/auth-template.component';
import { FaqCategoryComponent } from './faq-management/faq-category/faq-category.component';
import { FaqCategoryAddComponent } from './faq-management/faq-category-add/faq-category-add.component';
import { FaqCategoryViewComponent } from './faq-management/faq-category-view/faq-category-view.component';
import { ReportManagementComponent } from './advertisement-management/report-management/report-management.component';
import { UnifiedListComponent } from './pages/unified-list/unified-list.component';
import { UnifiedTransferComponent } from './pages/unified-transfer/unified-transfer.component';
import { EditFaqCategoryComponent } from './faq-management/edit-faq-category/edit-faq-category.component';
import { ListQuestionsFaqComponent } from './faq-management/list-questions-faq/list-questions-faq.component';
import { AddQuestionsFaqComponent } from './faq-management/add-questions-faq/add-questions-faq.component';
import { EditQuestionsFaqComponent } from './faq-management/edit-questions-faq/edit-questions-faq.component';
import { ViewQuestionsFaqComponent } from './faq-management/view-questions-faq/view-questions-faq.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    UserManagementComponent,
    //  HotWalletManagementComponent,
    MarketManagementComponent,
    SettingComponent,
    SubAdminManagementComponent,
    WalletManagementComponent,
    StaticsContentComponent,
    SidemenuComponent,
    AboutUsComponent,
    TermAndServiceComponent,
    PrivacyComponent,
    ExchangeManagementComponent,
    PaymentAndTransactionComponent,
    KycManagementComponent,
    TicketManagementComponent,
    AddWalletAddressComponent,
    WithdrawlFeeComponent,
    DailyLimitComponent,
    WithdrawlLimitComponent,
    PrevilageSettingComponent,
    ResetPasswordComponent,
    MyProfileComponent,
    EditProfileComponent,
    BannerSettingComponent,
    WebsiteContentSettingComponent,
    KycActionPageComponent,
    PageNotFoundComponent,
    FiatManagementComponent,
    FeeManagementComponent,
    HotcoldwalletManagementComponent,
    ViewtransactionComponent,
    TradeManagementComponent,
    DisputeManagementComponent,
    TradeDetailsComponent,
    DisputeTradeDetailsComponent,
    CreateSubadminComponent,
    UserDetailsComponent,
    DocumentDetailsComponent,
    ProfitLossComponent,
    EnquireManagementComponent,
    WithdrawLimitComponent,
    WalletdetailsComponent,
    StaffManagementComponent,
    AddNewStaffComponent,
    EditStaffComponent,
    ViewStaffComponent,
    FooterComponent,
    AdminManagementComponent,
    AddAdminComponent,
    EditAdminComponent,
    ViewAdminComponent,
    UserManagementExchangeComponent,
    ViewUserManagementExchangeComponent,
    ViewUserManagementExchangeOfFeedbackComponent,
    ViewUserTradingComponent,
    ChangePasswordComponent,
    TakerMakerFeeComponent,
    BankManagementComponent,
    LogsManagementComponent,
    AdvertisementManagementComponent,
    AdvertisementDetailsComponent,
    TicketDetailsComponent,
    TicketReplyComponent,
    EscrowManagementComponent,
    ReplaceUnderScorePipe,
    CustomPipePipe,
    DisclaimerComponent,
    FeedbackListComponent,
    FeedbackViewComponent,
    AddFaqComponent,
    EditFaqComponent,
    FaqComponent,
    ViewFaqComponent,
    AddBlogComponent,
    EditBlogComponent,
    ListBlogComponent,
    ViewBlogComponent,
    StaticContentAddComponent,
    ListAnnouncementComponent,
    EditAnnouncementComponent,
    AddAnnouncementComponent,
    ViewAnnouncementComponent,
    PartnershipComponent,
    ContactusManagementComponent,
    CommunityAddComponent,
    CommunityEditComponent,
    CommunityListComponent,
    CarrerCategoryListComponent,
    CarrerCategoryAddComponent,
    CareerCategoryEditComponent,
    CareerCategoryViewComponent,
    ListingUserListComponent,
    CareerSubcategoryEditComponent,
    CareerSubcategoryAddComponent,
    CareerSubcategoryListComponent,
    CareerSubcategoryViewComponent,
    ListingUserViewComponent,
    UserDeatilsNomineeComponent,
    ContactusViewComponent,
    ListingCoinComponent,
    ManageCoinListComponent,
    TokenManagementComponent,
    FundsListComponent,
    AdminBankComponent,
    AdminBankListComponent,
    AdminBankViewComponent,
    ViewFundWithdrawComponent,
    ViewFundUpiComponent,
    ListingCoinEditComponent,
    AddTeamComponent,
    ListTeamComponent,
    EditTeamComponent,
    ViewTeamComponent,
    XindiaDetailsListComponent,
    XindiaDetailsAddComponent,
    XindiaHoldingAddComponent,
    AddXindiaTableComponent,
    EditXindiaTableComponent,
    ViewXindiaTableComponent,
    XindiaBurnedstatusComponent,
    ListPartnershipComponent,
    AddPartnershipComponent,
    ViewPartnershipComponent,
    EditPartnershipComponent,
    ListBannerComponent,
    AddBannerComponent,
    EditBannerComponent,
    ViewBannerComponent,
    ReferralComponent,
    ViewDepositComponent,
    ViewWithdrawComponent,
    CommissionManagementListComponent,
    NomineeManagementComponent,
    NomineeViewComponent,
    AuthTemplateComponent,
    FaqCategoryComponent,
    FaqCategoryAddComponent,
    FaqCategoryViewComponent,
    ReportManagementComponent,
    UnifiedListComponent,
    UnifiedTransferComponent,
    EditFaqCategoryComponent,
    ListQuestionsFaqComponent,
    AddQuestionsFaqComponent,
    EditQuestionsFaqComponent,
    ViewQuestionsFaqComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MyDatePickerModule,
    NgxPaginationModule,
    DigitOnlyModule,
    CKEditorModule
  ],
  providers: [ServiceService, DatePipe, BnNgIdleService],
  bootstrap: [AppComponent]
})
export class AppModule { }
