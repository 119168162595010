import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-questions-faq',
  templateUrl: './edit-questions-faq.component.html',
  styleUrls: ['./edit-questions-faq.component.css']
})
export class EditQuestionsFaqComponent implements OnInit {
  addFaqForm : FormGroup
  id:any
  catID:any
  catName:any
  qsnID:any
  ansId:any
  constructor(public service:ServiceService,private router:Router,private activatedRoute:ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.catID = res.catID
      this.catName = res.catName,
      this.qsnID = res.qsnID,
      this.ansId = res.ansId
    })
   }

  ngOnInit() {
    this.formValidation()
    this.getAnsewer()
  }
  formValidation() {
    this.addFaqForm = new FormGroup({
      'faq_category': new FormControl('', [Validators.required]),
      // 'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      // 'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }
  addFaq(){
    let request = {
      answer : this.addFaqForm.value.faq_category
    }
    this.service.putApi(`static/admin/update-answer?answerId=${this.ansId}`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.router.navigate(['/list-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName,ansId:this.ansId}})
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // add faq
  back(){
    this.router.navigate(['/list-faq-question'],{queryParams:{qsnID: this.qsnID,catID : this.catID,catName: this.catName,ansId:this.ansId}})
  }
  editData:any
 getAnsewer(){
  let url =`static/admin/get-answer?answerId=${this.ansId}`
  this.service.get(url).subscribe((res: any) => {
    if (res.status = 200) {
      this.editData = res.data;
      this.addFaqForm.patchValue({
        'faq_category': this.editData.answer
      })
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Something Went Wrong');
    }
  })
 }

}
