import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
declare var $: any
@Component({
  selector: 'app-funds-list',
  templateUrl: './funds-list.component.html',
  styleUrls: ['./funds-list.component.css']
})
export class FundsListComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 10;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";
  addAmountForm: FormGroup
  today: any = new Date().toISOString().slice(0, 16)
  currTab: any = 'HOT';
  withdrawForm: FormGroup;
  feesSetForm: FormGroup;
  InrDepositInrAmount: FormGroup;
  nomineeForm: FormGroup
  flag: any
  dashStatus: any
  constructor(private router: Router, public service: ServiceService, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
      console.log(this.flag);
      if (this.flag == 'complete') {
        this.dashStatus = 'COMPLETED'
        console.log(this.dashStatus);
      } else if (this.flag == 'pending') {
        this.dashStatus = 'PENDING'
      } else if (this.flag == 'wcomplete') {
        this.currTab = 'COLD'
        this.dashStatus = 'COMPLETED'
      } else if (this.flag == 'wpending') {
        this.currTab = 'COLD'
        this.dashStatus = 'PENDING'
      }
    })
  }

  ngOnInit() {
    this.addAmountFormValidation()
    this.checkTransferFormValidations();
    this.widthdrawFormValidation()
    this.nomineeFormValue()
    this.feeSet();
    this.amountMin();
    // this.sidemenu();
    // this.getCoinWalletList();
    this.getAllTransactionHistory();
    this.getWithdralHistoryInr()

  }
  nomineeFormValue() {
    this.nomineeForm = new FormGroup({
      nomineeFeeValue: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }
  addAmountFormValidation() {
    this.addAmountForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)])
    })
  }

  widthdrawFormValidation() {
    this.withdrawForm = new FormGroup({
      status: new FormControl(''),
    })
    if (this.flag == 'wcomplete') {
      this.withdrawForm.patchValue({
        status: 'COMPLETED'
      })
    } else if (this.flag == 'wpending') {
      this.withdrawForm.patchValue({
        status: 'PENDING'
      })
    }
  }
  feeSet() {
    this.feesSetForm = new FormGroup({
      depositFee: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      withdrawFee: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
    })
  }
  amountMin() {
    this.InrDepositInrAmount = new FormGroup({
      depositAmountMin: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      withdrawAmountMin: new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
    })
  }
  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  pagination(page) {

    this.currentPage = page
    if (this.flip == "searchCoin") {
      this.ekFunctionZindgiKa();
    } else if (this.flip == "searchCoinn") {
      // this.searchcoinn();
    }
    else {
      this.getAllTransactionHistory()
    }

  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'type': new FormControl(''),
      'userEmail': new FormControl(''),
    })
    if (this.flag == 'complete') {
      this.transferForm.patchValue({
        type: 'COMPLETED'
      })
    } else if (this.flag == 'pending') {
      this.transferForm.patchValue({
        type: 'PENDING'
      })
    }
  }
  ekFunctionZindgiKa() {
    let type = this.transferForm.value.type;
    let userEmail = this.transferForm.value.userEmail
    // let url = `wallet/admin/Deposi-Inr-List-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.type ? "&fiatStatus=" + type : ""}${this.transferForm.value.userEmail ? "&utrNo=" + userEmail : ""}`
    let url = `wallet/admin/get-user-transaction-history-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.type ? "&status=" + type : ""}&depositType=FIAT_DEPOSIT`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.loopData = res.data.resultlist;
        }
        this.lengthTotal = res['data'].totalCount
        //  this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  searchByWithdrawList() {
    this.service.showSpinner();
    let status = this.withdrawForm.value.status;
    // this.lengthTotal = 0;wallet/admin/Deposi-Inr-List-admin?page=0&pageSize=10
    let url = `wallet/admin/get-user-transaction-history-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.withdrawForm.value.status ? "&status=" + status : ""}&depositType=FIAT_WITHDRAW`
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.widtralDataInr = res.data.resultlist;
        }
        this.lengthTotal = res['data'].totalCount
        //  this.totalRecords = res.data.totalCount;
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  // resetWithdraw() {
  //   if (this.withdrawForm.value.status) {
  //     this.withdrawForm.reset({
  //       status: ""
  //     })
  //     this.getWithdralHistoryInr()
  //   }
  // }

  resetWithdraw() {
    this.withdrawForm.reset({
      fiatStatus: ""
    })
    this.dashStatus = ""
    this.getWithdralHistoryInr();
  }

  // reset() {
  //   if (

  //     this.transferForm.value.type ||
  //     this.transferForm.value.userEmail

  //   ) {
  //     this.transferForm.reset({
  //       type: "",
  //       userEmail: "",
  //     });
  //     this.getAllTransactionHistory();
  //   }
  // }

  reset() {
    this.transferForm.reset({
      type: "",
      userEmail: "",
    });
    this.dashStatus = ""
    this.getAllTransactionHistory();
  }

  getAllTransactionHistory() {
    this.service.showSpinner()
    // this.loopData = []
    let type = this.transferForm.value.type;

    let url = `wallet/admin/get-user-transaction-history-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.type ? "&status=" + type : ""}&depositType=FIAT_DEPOSIT`

    // this.lengthTotal = 0;wallet/admin/Deposi-Inr-List-admin?page=0&pageSize=10
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res['data'].totalCount

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })

  }
  widtralDataInr: any = []
  lengthData: any
  getWithdralHistoryInr() {
    this.service.showSpinner()
    // this.loopData = []
    let type = this.transferForm.value.type;
    // this.lengthTotal = 0;wallet/admin/Deposi-Inr-List-admin?page=0&pageSize=10
    let url = `wallet/admin/get-user-transaction-history-admin?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.type ? "&status=" + type : ""}&depositType=FIAT_WITHDRAW`

    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      this.widtralDataInr = res.data.resultlist;
      this.lengthData = res['data'].totalCount

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })

  }
  changeTicketStatus(fiatStatus, utrNo) {
    let data = {}
    this.service.post(`wallet/admin/Deposit-Inr-Status?fiatStatus=${fiatStatus}&utrNo=${utrNo}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.getAllTransactionHistory()
      }
    })
  }
  changeWithdrawStatus(userId1, amount,fiatStatus) {
    let data = {}

    this.service.post(`wallet/admin/withdraw-Inr-Status?fiatStatus=${fiatStatus}&userId1=${userId1}&amount=${amount}`, data).subscribe((res) => {
      if (res['status'] == 200) {
        this.getAllTransactionHistory()
      }
    })
  }
  ngOnDestroy() {
    $('body>#setFeesModal').remove();
    $('body>#setNomineeFee').remove();
    $('body>#transferModal').remove();
    $('body>#setMinimumAmount').remove();
  }
amnAdd(){
  $('#transferModal').appendTo('body').modal('show');
}

  addAmountvalue() {
    let url = `wallet/admin/add-Amount`
    let data = {
      amount: this.addAmountForm.value.amount
    }
    this.service.post(url, data).subscribe((res) => {
      if (res['status'] == 200) {
        // $("#transferModal").modal("hide");
        // $('#transferModal').modal({ backdrop: 'false' });
        $('#transferModal').appendTo('body').modal('hide');
        this.getAllTransactionHistory()
      }
    })
  }

  setFeesValueModal() {
    $('#setFeesModal').appendTo('body').modal('show');
  }
  setFeesValue() {
    let url = `wallet/admin/update-deposit-withdraw-fee?deposite=${this.feesSetForm.value.depositFee}&withdraw=${this.feesSetForm.value.withdrawFee}`
    this.service.post(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        $('#setFeesModal').appendTo('body').modal('hide');
        // $("#setFeesModal").modal("hide");
        this.getAllTransactionHistory()
      }
    })
  }

  setNomFee(){
    $('#setNomineeFee').appendTo('body').modal('show');

  }
  setNomineeFees() {
    let url = `account/add-nominee-fee?nomineeFee=${this.nomineeForm.value.nomineeFeeValue}`
    this.service.post(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        // $('#setNomineeFee').modal();
        $('#setNomineeFee').modal('hide');
        this.getAllTransactionHistory()
      }
    })
  }

  minSet(){
    $('#setMinimumAmount').appendTo('body').modal('show');
  }
  setMinimumAmountValue() {
    let url = `wallet/admin/update-minimum-deposit-withdraw-fee?minimumdeposite=${this.InrDepositInrAmount.value.depositAmountMin}&minimumwithdraw=${this.InrDepositInrAmount.value.withdrawAmountMin}`

    this.service.post(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        $('#setMinimumAmount').modal('hide');
        // $("#setFeesModal").modal("hide");
        this.getAllTransactionHistory()
      }
    })

  }
  selectTab(tab) {
    this.currTab = tab;
    if (tab == 'HOT') {
      this.coinListArr = [];
      this.getAllTransactionHistory()
    } else {
      this.getWithdralHistoryInr()
    }
    // this.getCoinWalletList();
  }
 

  viewBody(data) {
    let dataNewWithdarwa = JSON.stringify(data)
    sessionStorage.setItem('dataNewWithdarwa',dataNewWithdarwa)
    this.router.navigate(['/fund-view']);

  }
  viewDeposit(data){
    let dataNew = JSON.stringify(data)
    sessionStorage.setItem('data',dataNew)
    this.router.navigate(['/view-deposit'])
  }
  viewWithdraw(bank, userId, utrNo, fiatId, fiatStatus, amount) {
    // alert(announcementId)
    // this.router.navigate(['/view-announcement'], { queryParams: { announcementId: announcementId } })
    this.router.navigate(['/fund-view'], { queryParams: { bankName: bank, userId: userId, utrNo: utrNo, fiatId: fiatId, fiatStatus: fiatStatus, amount: amount } });

  }
  viewUpi(upiId, userId) {
    this.router.navigate(['/upi-view'], { queryParams: { upiId: upiId, userId: userId } });
  }
  fiatCryptoData: any = "CRYPTO"


  fiatCryptoList(datafiatCrypto, event) {
    this.fiatCryptoData = datafiatCrypto
  }

  disableSearchbtn() {

    if (this.transferForm.value.type || this.transferForm.value.userEmail || this.withdrawForm.value.status) {
      return false
    }
    else {
      return true
    }

  }
}

