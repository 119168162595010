import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

declare var $: any
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 20
  totalItems: any;
  fromDate: any = ''
  beDisable: boolean = true;
  userForm: FormGroup;
  userID: any;
  IsToDate: boolean = true;
  calender: any = { todate: '', formdate: '' }
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  newArray: any = [];
  newFromDate: number;
  newToDate: any;
  countryList: any = []
  flag: any
  dashStatus: any
  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router, public service: ServiceService, public activatedRoute: ActivatedRoute,private http:HttpClient) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
      console.log(this.flag);
      if (this.flag == 'active') {
        this.dashStatus = 'ACTIVE'
        console.log(this.dashStatus);
      } else if (this.flag == 'block') {
        this.dashStatus = 'BLOCK'
      }
    })
  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'sedan': new FormControl(''),
      'hatch': new FormControl(''),
      'suv': new FormControl(''),
      'country': new FormControl(''),
      'status': new FormControl('')
    })
    this.onDateChanged();
    this.getUserList();
    this.getCountryList()
    if (this.flag == 'active') {
      this.userForm.patchValue({
        status: 'ACTIVE'
      })
    } else if (this.flag == 'block') {
      this.userForm.patchValue({
        status: 'BLOCK'
      })
    }

  }

  // reset() {
  //   if (
  //     this.userForm.value.searchText ||
  //     this.userForm.value.startdate ||
  //     this.userForm.value.enddate ||
  //     this.userForm.value.sedan ||
  //     this.userForm.value.hatch ||
  //     this.userForm.value.suv ||
  //     this.userForm.value.country ||
  //     this.userForm.value.status


  //   ) {
  //     this.userForm.reset({
  //       startdate: "",
  //       enddate: "",
  //       searchText: "",
  //       sedan: '',
  //       hatch: '',
  //       suv: '',
  //       country: '',
  //       status: ''
  //     });
  //     this.getUserList();
  //   }
  // }

  reset() {
    this.userForm.reset({
      startdate: "",
      enddate: "",
      searchText: "",
      sedan: '',
      hatch: '',
      suv: '',
      country: '',
      status: ''
    });
    this.dashStatus = ""
    this.getUserList();
  }

  // get country list
  getCountryList() {
    this.http.get('assets/contry.json').subscribe((res) => {
        this.countryList = res
    })
  }
  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }
  /*******************Date managing Ends Here**************/


  // Get List of User
  getUserList() {
    var url = `account/admin/user-management/filter-user-details?page=${this.pageNumber - 1}&pageSize=${this.pageSize}${this.dashStatus ? "&status=" + this.dashStatus : ""}`;
    // var url = "account/admin/user-management/filter-user-details?&page=1";
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data']['list'];
        this.totalItems = res.data.totalCount;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner()
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Apply Filter
  applyFilter(val) {
    if (val == 'date') {
      var startdate = this.userForm.value.startdate.epoc ? (Number(this.userForm.value.startdate.epoc) * 1000) : ''
      var enddate = this.userForm.value.enddate.epoc ? (Number(this.userForm.value.enddate.epoc) * 1000) : ''
      var url = "account/admin/user-management/filter-user-details?fromDate=" + startdate + "&page=" + (this.pageNumber - 1) + "&toDate=" + enddate + "&pageSize=10";
    } else {
      var url = "account/admin/user-management/filter-user-details?&page=" + (this.pageNumber - 1) + "&search=" + this.userForm.value.searchText + "&pageSize=10";
    }
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data']['list'];
      } else {
        this.userList = []
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  // Delete / Block Function
  openModal(action, userId) {
    this.userID = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  p2pStatusUserID:any
  p2pStatusUser:any
  openModals(userId) {
    this.userID = userId
    $('#activate').modal('show')
  }
  openModalNew(status,userId){
    this.p2pStatusUser= status
    this.p2pStatusUserID = userId
    if (status == 'BLOCK') {
      $('#blockP2p').modal('show')
    }
    else {
      $('#activep2p').modal('show')
    }
  }
  activateUser() {
    var url = 'account/admin/user-management/Activate-Account?userIdForStatusUpdate=' + (this.userID);
    this.service.showSpinner();
    this.service.postApi(url, {}).subscribe(res => {

      if (res['status'] == 200) {
        this.service.hideSpinner();
        $('#activate').modal('hide')
        this.service.toasterSucc('User Activate Successfully');
        this.getUserList();
      } else {
        this.userList = []
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }



  //User Details
  userdetails(userId, email) {
    this.router.navigate(['/user-details/'], { queryParams: { userId, email } })
  }
  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "User Name": element.firstName + '' + element.lastName ? element.firstName : '',
        "Email": element.email ? element.email : 'N/A',
        "Unique ID": element.randomId ? element.randomId : 'N/A',
        "Mobile Number": element.phoneNo ? element.phoneNo : 'N/A',
        "Country": element.country ? element.country : 'N/A',
        "Status": element.userStatus ? element.userStatus : 'N/A',
        "Registration Date & Time": element.createTime ? element.createTime.slice(0, 10) : 'N/A',
      })
      // let fhkds= JSON.stringify(dataArr)
    })

    this.service.exportAsExcelFile(dataArr, 'Admin User list');
  }

  // Delete User
  deleteFunction() {
    var url = 'account/admin/user-management/delete-user-detail?userIdToDelete=' + (this.userID) + '&ipAddress=' + (6754675467) + '&location=' + ('unknown');
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User Deleted Successfully');
        this.getUserList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  performAction() {
    var url = `account/admin/user-management/activate-deactivate-delete-block-user-account?userIdForStatusUpdate=${this.userID}&userStatus=${this.action}`
    this.service.showSpinner();
    this.service.putApi(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'BLOCK') {
          $('#block').modal('hide');
          this.service.toasterSucc('User Blocked Successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('User Activated Successfully');
        }
        this.getUserList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  performActionNew() {
    let url =`account/admin/user-management/update-user-p2p-status?p2pStatus=${this.p2pStatusUser}&userId=${this.p2pStatusUserID}`
    // var url = `account/admin/user-management/activate-deactivate-delete-block-user-account?userIdForStatusUpdate=${this.userID}&userStatus=${this.action}`
    this.service.showSpinner();
    this.service.putApi(url, '').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'BLOCK') {
          $('#blockP2p').modal('hide');
          this.service.toasterSucc('P2P Status Blocked Successfully');
        }
        else {
          $('#activep2p').modal('hide');
          this.service.toasterSucc('P2P Status Activated Successfully');
        }
        this.getUserList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  getlist() {
    let startdate = Date.parse(this.userForm.value.startdate);
    // alert(new Date(this.userForm.value.startdate).getHours() )
    let enddate = Date.parse(this.userForm.value.enddate);
    // let startdate =   this.userForm.value.startdate ? new Date(this.userForm.value.startdate).toISOString() : '';
    // let enddate = this.userForm.value.enddate ? new Date(this.userForm.value.enddate).toISOString() : '';
    var search = this.userForm.value.searchText;
    let countryDa = this.userForm.value.country;
    let status = this.userForm.value.status
    let url = `account/admin/user-management/filter-user-details?page=${this.pageNumber - 1}&pageSize=${this.pageSize}${this.userForm.value.searchText ? "&search=" + search : ""}${this.userForm.value.startdate ? "&fromDate=" + startdate : ""}${this.userForm.value.enddate ? "&toDate=" + enddate : ""}${this.userForm.value.country ? "&country=" + countryDa : ""}${this.userForm.value.status ? "&status=" + status : ""}`;
    // var url = "account/admin/user-management/filter-user-details";
    // var url =
    // "account/admin/user-management/filter-user-details?page=" +
    // (this.pageNumber - 1) +
    // "&pageSize=10";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.userList = res['data']['list'];
          this.totalItems = res.data.totalCount;
        }

        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  searchByDate() {

    if (this.userForm.value.startdate || this.userForm.value.enddate) {
      this.newFromDate = Math.round(new Date(this.userForm.value.startdate).getTime())
      this.newToDate = Math.round(new Date(this.userForm.value.enddate).getTime())
      this.service.showSpinner()
      this.service.get('account/admin/user-management/filter-user-details?fromDate=' + (this.userForm.value.startdate.epoc * 1000) + '&toDate=' + (this.userForm.value.enddate.epoc * 1000)).subscribe((res: any) => {
        this.service.hideSpinner()
        this.userList = res['data']['list'];
      })
    } else if (this.userForm.value.searchText) {

      this.service.showSpinner()
      this.service.get('account/admin/user-management/filter-user-details?search=' + (this.userForm.value.searchText)).subscribe((res: any) => {
        this.service.hideSpinner()//cryptocurrencyjava.mobiloitte.com/account/admin/user-management/filter-user-details?search=Navneet
        if (res.status == 200) {
          this.userList = res['data']['list'];
          this.totalItems = res.data.totalCount;
        }
        else {
          this.userList = [];
          // this.userList = res['data']['list'];
        }
      })
    }
    else if (this.userForm.value.country) {

      this.service.showSpinner()
      this.service.get('account/admin/user-management/filter-user-details?country=' + (this.userForm.value.country)).subscribe((res: any) => {
        this.service.hideSpinner()//cryptocurrencyjava.mobiloitte.com/account/admin/user-management/filter-user-details?search=Navneet
        if (res.status == 200) {
          this.userList = res['data']['list'];
          this.totalItems = res.data.totalCount;
        }
        else {
          this.userList = [];
          // this.userList = res['data']['list'];
        }
      })
    }
    else if (this.userForm.value.status) {

      this.service.showSpinner()
      this.service.get('account/admin/user-management/filter-user-details?status=' + (this.userForm.value.status)).subscribe((res: any) => {
        this.service.hideSpinner()//cryptocurrencyjava.mobiloitte.com/account/admin/user-management/filter-user-details?search=Navneet
        if (res.status == 200) {
          this.userList = res['data']['list'];
          this.totalItems = res.data.totalCount;
        }
        else {
          this.userList = [];
          // this.userList = res['data']['list'];
        }
      })
    }
  }

  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
    this.IsToDate = false;

  }

  goAdmin() {
    this.router.navigate(['/admin-management'])
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.getlist();
    } else {
      this.getUserList();
    }
  }



  disableSearchbtn() {

    if (this.userForm.value.startdate || this.userForm.value.enddate || this.userForm.value.country || this.userForm.value.status || this.userForm.value.searchText) {
      return false
    }
    else {
      return true
    }

  }


}


