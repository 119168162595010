import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {
  addFaqForm: FormGroup;
  pageNumber: number = 1
  qsnID: any;
  editData: any;
  catID: any;
  catName:any
  constructor(public route: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.qsnID = res.qsnID;
      this.catID = res.catID;
      this.catName = res.catName
    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.editFaq()
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'faq_category': new FormControl(''),
      'title': new FormControl('', [Validators.required, Validators.pattern('')]),
    })
  }
  
  // edit faq english
  editFaq() {
    let url =`static/admin/get-questions?questionId=${this.qsnID}`
    this.service.get(url).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.addFaqForm.patchValue({
          'title': this.editData.question,
          'faq_category' : this.catName
        })
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
 
  
  // update faq english
  addFaq() {
    let request = {
      'question': this.addFaqForm.value.title
    }
    let url =`static/admin/update-questions?questionId=${this.qsnID}`
    this.service.putApi(url,request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'],{queryParams : {id : this.catID,catName: this.catName}})
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  backToqsn(){
    this.route.navigate(['/faq-management'],{queryParams : {id : this.catID,catName: this.catName}})
  }
}
