import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-faq-category',
  templateUrl: './faq-category.component.html',
  styleUrls: ['./faq-category.component.css']
})
export class FaqCategoryComponent implements OnInit {

  constructor(public service:ServiceService,private router:Router) { }
  faqCatgeory:any = []
  itemsPerPage: any = 20
  pageNumber: any =  1
  ngOnInit() {
    this.getFaqCategory()
  }
  getFaqCategory(){
    let url = `static/get-category`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.faqCatgeory = res.data;
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  pagination(page){
    this.pageNumber = page;
  }
  viewFaq(catId, catName){
    this.router.navigate(['/faq-management'],{queryParams : {id:catId,catName: catName }})
  }
  editFaq(id){
    this.router.navigate(['/edit-faq-category'],{queryParams: {id:id}})
  }
}
