import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.css']
})
export class AddFaqComponent implements OnInit {
  addFaqForm: FormGroup;
  pageNumber: number = 1
  currTab: any;
  catName: any;
  constructor(public route: Router, public service: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((params) => {
      this.currTab = params.catID
      this.catName = params.catName
      
    })
  }

  ngOnInit(): void {
    this.formValidation();
    this.getFaqCategory()
  }

  formValidation() {
    this.addFaqForm = new FormGroup({
      'faq_category': new FormControl('', [Validators.required]),
      'title': new FormControl('', [Validators.required, Validators.pattern('')]),
      // 'description': new FormControl('', [Validators.required, Validators.pattern('')])
    })
  }
  // add faq english
  addFaq() {
    let request = {
      'categoryId': this.addFaqForm.value.faq_category,
      'question': this.addFaqForm.value.title

    }
    this.service.post(`static/admin/add-questions`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'],{queryParams : {id:this.currTab,catName:this.catName}})
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  // add faq german
  addFaqGerman() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
    }
    this.service.post(`static/add-new-german-faq?page="+(this.pageNumber-1)+ "&pageSize=10"`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  faqCatgeory : any = []
  getFaqCategory(){
    let url = `static/get-category`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.faqCatgeory = res.data;
          this.addFaqForm.patchValue({
            faq_category : this.currTab
          })
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (error) => {
        this.service.hideSpinner();
      }
    );
  }
  // add faq spanish
  addFaqSpanish() {
    let request = {
      'answer': this.addFaqForm.value.description,
      'question': this.addFaqForm.value.title,
    }
    this.service.post(`static/add-new-spanish-faq?page="+(this.pageNumber-1)+ "&pageSize=10"`, request).subscribe((res: any) => {
      if (res.status = 200) {
        this.service.toasterSucc(res.message)
        this.route.navigate(['/faq-management'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
  backToqsn(){
    this.route.navigate(['/faq-management'],{queryParams : {id : this.currTab,catName: this.catName}})
  }
}
