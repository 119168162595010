import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ServiceService } from "../service.service";
declare var $: any;
@Component({
  selector: "app-token-management",
  templateUrl: "./token-management.component.html",
  styleUrls: ["./token-management.component.css"],
})
export class TokenManagementComponent implements OnInit {
  currTab: any = "Price";
  pageNumber: number = 1;
  transferAll: any;
  pageSize: number = 10;
  // pageNumber: number=1;
  pageNumbers: number = 1;
  pageNumberss: number = 1;
  itemsPerPage: number = 10;
  coinData: any = [];
  coinShortName: any;
  priceForm: FormGroup;
  priceData: any;
  coinName: any = [];
  coin: any = [];
  priceDataa: any;
  transactionData: any = [];
  transactionLength: any;
  transferForm: FormGroup;
  buyTransactionData: any = [];
  tab: any = "Buy";
  withdrawTransactionData: any = [];
  transferData: any = [];
  datetime: any;
  date: any;
  time: any;
  tabCoin: any = "All";
  excel: boolean = false;
  mytab: boolean;
  mytab1: boolean;
  coinhistorytab: boolean;
  coinTokenForm: FormGroup;
  isAssending: boolean = true;
  minimumFee: any
  transferFee: any

  constructor(private router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.forValidation();
    this.coinList();
    this.coinTokenForm = new FormGroup({
      coinNameToken: new FormControl(""),
      // 'userName': new FormControl('', Validators.required),
    });
    // this.getXindiaPrice()
  }

  reset() {
    if (
      this.coinTokenForm.value.coinNameToken
    ) {
      this.coinTokenForm.reset({
        coinNameToken: "",
      });
      this.adminTransferCrypto();
    }
  }
  // form Validation
  forValidation() {
    this.priceForm = new FormGroup({
      price: new FormControl("", [Validators.required, Validators.pattern("")]),
    });
    this.transferForm = new FormGroup({
      price: new FormControl("", [Validators.required, Validators.pattern("")]),
      address: new FormControl("", [
        Validators.required,
        Validators.pattern(""),
      ]),
      coin: new FormControl("AVT", [
        Validators.required,
        Validators.pattern(""),
      ]),
    });
  }

  selectTab(tab) {
    this.currTab = tab;
    if (this.currTab === "Price") {
      this.coinList();
    } else if (this.currTab === "User") {
      this.mytab = true;
      this.tab == "Buy";
      this.buyGetTransaction();
      this.select(this.tab === "Buy");
    } else if (this.currTab === "Admin") {
      // this.loginSession()
      this.mytab1 = true;
      this.select(tab);
    } else if (this.currTab === "History") {
      this.adminTransfer();

    }
  }

  select(tab) {
    this.tab = tab;
    if (this.tab === "Buy") {
      this.mytab = true;
      this.buyGetTransaction();
    } else if (this.tab === "Withdraw") {
      this.mytab = false;
      this.withdrawGetTransaction();
    } else if (this.tab === "Transfer") {
      this.mytab1 = true;
    } else if (this.tab === "History") {
      this.mytab1 = false;
      this.adminTransfer();
      // this.adminTransferFiat();
      // this.adminTransferCrypto();
    }
  }

  // cointhistory tab
  coinSelectHistory(tabCoin) {
    this.tabCoin = tabCoin;
    if (this.tabCoin === "All") {
      this.coinhistorytab = true;
      this.adminTransfer();
    } else if (this.tabCoin === "Fiat") {
      this.coinhistorytab = false;
      // this.withdrawGetTransaction()
      this.adminTransferFiat();
    } else if (this.tabCoin === "Crypto") {
      this.coinhistorytab = true;
      this.adminTransferCrypto();
    }
    // else if(this.tab==='History'){
    //   this.mytab1=false;
    //   this.adminTransfer()
    // }
  }

  // coin list api
  coinList() {
    var url =
      "wallet/coin/admin/get-full-coin-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.coinData = res.data[9];
          this.coinData.forEach((element) => {
            this.coinName = element.coinShortName;
            console.log("h", this.coinName);
            this.coin.push(this.coinName);
          });
          console.log("h", this.coin);

          this.coinShortName = res.data[0].coinType;
          console.log("fg", this.coinShortName);
          this.getPrice();
          this.getXindiaPrice();
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // price details
  getPrice() {
    var url =
      "wallet/admin/hot-cold-storage/get-storage-details-coin-hot?coin=" +
      "XINDIA" +
      "&storageType=HOT";

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceData = res["data"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // get avt price
  getAvtPrice() {
    var url = "wallet/admin/admin/get-AVT-price-inUsd?coin=" + "XINDIA";
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceDataa = res["data"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  Change() {
    let priseInUsd = this.priceForm.value.price;
    // var url =
    //   "wallet/admin/admin/set-XINDIA-price-inUsd?coin=" +
    //   "XINDIA" +
    //   "&priseInUsd=" +
    //   priseInUsd;
    var url = `wallet/admin/admin/set-XINDIA-price-inUsd?coin=XINDIA&priseInUsd=${priseInUsd}`
    this.service.showSpinner();
    this.service.post(url, "").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.getXindiaPrice()
          this.priceDataa = res["data"];
          this.priceForm.reset();

        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  internalsFees() {
    var url = "wallet/admin/fee-management/set-transfer-fee?minimumFee=" + this.minimumFee + "&transferFee=" + this.transferFee
    this.service.showSpinner();
    this.service.post(url, "").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.coinList();
          this.service.toasterSucc(res['message'])
          this.priceDataa = res["data"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  xindiaPriceArray: any
  getXindiaPrice() {
    let url = `wallet/admin/admin/get-XINDIA-price-inUsd?coin=XINDIA`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.xindiaPriceArray = res['data']
          this.priceForm.reset();

        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  //  buy Transaction
  buyGetTransaction() {
    var url =
      "wallet/admin-basic-exchange/get-avt-buy-order?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10";

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceForm.reset();
          this.buyTransactionData = res["data"]["data"]["content"];
          this.transactionLength = res["data"]["data"].totalPages;
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  //  withdraw Transaction
  withdrawGetTransaction() {
    var url =
      "wallet/admin/transaction-history/get-all-transaction-history?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10" +
      "&coinName=AVT" +
      "&txnType=WITHDRAW";

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceForm.reset();
          this.withdrawTransactionData = res["data"]["resultlist"];
          this.transactionLength = res["data"].size;
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  isSearched: boolean = false;

  pagination(page) {
    // this.transferAll = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      this.search();
    } else {
      this.adminTransfer();
    }
    // this.adminTransfer();
  }
  transferFiat: any;
  paginationFiat(page) {
    // this.transferFiat = [];
    console.log("jh", page);
    this.pageNumbers = page;
    console.log("jh", this.pageNumber);

    this.adminTransferFiat();
  }
  transferCrypto: any;
  paginationCrypto(page) {
    // this.transferCrypto = [];
    console.log("jh", page);
    this.pageNumberss = page;
    console.log("jh", this.pageNumber);

    this.adminTransferCrypto();
  }
  //  admin Transaction
  adminTransfer(type?) {
    // var url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"+"&coinName=AVT"+"&txnType=AVT_TRANSFER"
    // var url = "wallet/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"+"&coinName=AVT"+"&txnType=AVT_TRANSFER"
    var url =
      "wallet/get-all-transaction-history?page=" +
      (this.pageNumber - 1) +
      "&pageSize=" +
      this.itemsPerPage + `${type ? "&coinName=" + type : ''}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceForm.reset();
          this.transferData = res["data"]["resultlist"];
          //  this.transferAll = res['data'].size
          this.transferAll = res["data"].totalCount;
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  transferDataAdd: any = [];
  adminTransferFiat() {
    // var url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"+"&coinName=AVT"+"&txnType=AVT_TRANSFER"
    // var url = "wallet/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"+"&coinName=AVT"+"&txnType=AVT_TRANSFER"
    // var url = "wallet/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"
    var url =
      "wallet/get-all-transaction-history?page=" +
      (this.pageNumbers - 1) +
      "&pageSize=" +
      this.itemsPerPage +
      "&coinName=AVT";

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceForm.reset();
          this.transferDataAdd = res["data"]["resultlist"];
          this.transferFiat = res["data"].totalCount;
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  transferDataCrypto: any = [];
  adminTransferCrypto() {
    // var url = "wallet/admin/transaction-history/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"+"&coinName=AVT"+"&txnType=AVT_TRANSFER"
    // var url = "wallet/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"+"&coinName=AVT"+"&txnType=AVT_TRANSFER"
    // var url = "wallet/get-all-transaction-history?page="+(this.pageNumber-1)+ "&pageSize=10"
    var url =
      "wallet/get-all-transaction-history?page=" +
      (this.pageNumberss - 1) +
      "&pageSize=10" +
      "&coinName=USDT";

    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.priceForm.reset();
          this.transferDataCrypto = res["data"]["resultlist"];
          this.transferCrypto = res["data"].totalCount;
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }
  transfer() {
    let data = {
      amount: this.transferForm.value.price,
      toAddress: this.transferForm.value.address,
      coinName: this.transferForm.value.coin,
    };
    var url = "wallet/admin/hot-cold-storage/manual-transfer-hot-to-cold";

    this.service.showSpinner();
    this.service.post(url, data).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.service.toasterSucc("Token transfer successfully");
          this.selectTab("Price");
          this.transferForm.reset();
          this.priceDataa = res["data"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  //========modal=======//
  delete() {
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    $("#deleteModal").modal("hide");
  }
  block() {
    $("#block").modal("show");
  }
  blockUser() {
    $("#block").modal("hide");
  }
  view() {
    this.router.navigate(["/view-token"]);
  }

  edit() {
    this.router.navigate(["/edit-token"]);
  }
  addToken() {
    this.router.navigate(["/add-token"]);
  }

  // Api of search

  // search(){
  //   this.service.showSpinner();
  //   let search=this.hotWalletForm.value.coin
  //   var url="wallet/admin/hot-cold-storage/get-storage-details-with-latestTime?coinName="+search;
  //   this.service.get(url).subscribe((res: any) => {
  //     console.log('j', res);
  //     this.service.hideSpinner();
  //     if(res.status==200){
  //       this.coinData=[]
  //     this.coinData.push(res.data)
  //     // this.coinData.push(str2)
  //     console.log('g', this.coinData);

  //     this.service.hideSpinner();
  //     }
  //   })
  // }
  search() {
    this.service.showSpinner();
    let kycStatus = this.coinTokenForm.value.coinNameToken;
    var url =
      "wallet/get-all-transaction-history?page=" +
      (this.pageNumberss - 1) +
      "&pageSize=10" +
      "&coinName=" +
      kycStatus;
    this.service.get(url).subscribe(
      (res: any) => {
        console.log("j", res);
        this.service.hideSpinner();
        if (res["status"] == 200) {
          console.log(res);

          this.transferDataCrypto = res["data"]["resultlist"];
        }
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  // reset
  // reset() {
  //   if (this.coinTokenForm.value.coinNameToken) {
  //     this.coinTokenForm.reset();
  //     this.adminTransferCrypto();
  //   }
  // }

  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.transferData = this.transferData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.transferData = this.transferData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  sortFiat(key) {
    if (this.isAssending) {
      this.transferDataAdd = this.transferDataAdd.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.transferDataAdd = this.transferDataAdd.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  sortingCrypto(key) {
    if (this.isAssending) {
      this.transferDataCrypto = this.transferDataCrypto.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.transferDataCrypto = this.transferDataCrypto.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
